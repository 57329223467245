import '../App.css';

import { useEffect, useState, useRef } from 'react';
import React, { createContext, useCallback } from 'react';

import BusinessDatePicker from "./BusinessDatePicker";

import ForwardRateSurface from './ForwardRateSurface';
import MarketDataPanel from './MarketDataPanel';
import IndexPanel from './IndexPanel';
import SwapPanel from './SwapPanel';
import QuickSetupPanel from './QuickSetupPanel';
import CashflowsPanel from './CashflowsPanel';
import Results from './Results';
import PricerHelper from './PricerHelper'
import LogonPanel from './LogonPanel'
import SessionHelper from './SessionHelper'
import RatesMarketDataPanel from './RatesMarketDataPanel'
import AnalyticsPanel from './AnalyticsPanel'

import 'react-datepicker/dist/react-datepicker.css';

import { Container, Row, Col } from 'react-bootstrap/';
import Accordion from 'react-bootstrap/Accordion';
import { compareAsc, format, parseISO } from 'date-fns'

import { TailSpin } from  'react-loader-spinner'

//import { SwapIndexes, SwapIndexTenors } from './data/SwapIndexes'

//import helpers from './components/helpers'


const InterestRateSwaps = React.forwardRef ((props, ref) => {


  {/*
  const ratesMarketDataPanelRef = useRef()
  const [valuationDate, setValuationDate] = useState(new Date())
  const [pricingData, setPricingData] = useState()
  const [positions, setPositions] = useState()
  const [exposure, setExposure] = useState()
  const [logonResponse, setLogonResponse] = useState()
  const [tradeBooked, setTradeBooked] = useState()
  const [toggle, setToggle] = useState(false)
  const [setupTenor, setSetupTenor] = useState()
  const [pricingDisabled, setPricingDisabled] = useState()
  const [curvePricingData, setCurvePricingData] = useState()
  */}

  const [valuationDate, setValuationDate] = useState(new Date())
  const [pricingData, setPricingData] = useState()
  const [pricingDisabled, setPricingDisabled] = useState()

  useEffect(() => {

    if ( props.marketData !== undefined &&
        props.keyRates !== undefined &&
        props.valuationDate !== undefined &&
        props.rowData !== undefined )
    {
      //console.log(props.marketData['INDEX_RATES'])

      var index_rates = {}

      props.marketData['INDEX_RATES'].forEach((item, i) => {
        console.log(item['series_id'])
        index_rates[item['series_id']] = parseFloat(item['data']['value'])/100.0
      });

      console.log(index_rates)

      var market_data = {}
      market_data['INDEX_RATES'] = index_rates
      market_data['KEY_RATES'] = props.keyRates
      market_data['VALUATION_DATE'] = new Date(valuationDate).toISOString().slice(0, 10)

      var pricing_data = {}
      pricing_data['market_data'] = market_data
      pricing_data['curve_out'] = props.rowData['curve_out']

      setPricingData(pricing_data)

    }
    /*PricerHelper.get_market_data((data) =>
    {

      var swap_data_out = {};

      data.data.swap_data.forEach((curve_date, i) => {
          var curve_name = curve_date['curve_name']
          var rates = curve_date['rates']

          if ( swap_data_out[curve_name] == undefined )
          {
            swap_data_out[curve_name] = {}
          }

          swap_data_out[curve_name][curve_date['price_date']] = rates
        }
      )
      setMarketData(swap_data_out)
    })*/



  }, [props.marketData, props.keyRates, props.rowData])

  /*
  useEffect(() => {

   if ( logonResponse != undefined )
   {
       var position_request = {}
       position_request = {}
       position_request['email'] = logonResponse['email']
       position_request['jti'] = logonResponse['jti']

       SessionHelper.get_positions(position_request, (positions_in) =>
         {
           if ( positions_in !== undefined && positions_in.positions !== undefined )
           {
             var positions_out = {}
             positions_in.positions.forEach((position, i) => {
               positions_out[position['symbol']] = position
             })

             setPositions(positions_out)
           }
         })
       }
  }, [logonResponse, tradeBooked]);*/

  return (
      <div className="ag-theme-balham-dark">
      <AnalyticsPanel
        pricingDisabledCallBack =
        { (message) => { setPricingDisabled() } }
        pricingData={pricingData}/>
        {/*
          <div style={{marginLeft:'0px'}}>
          <Accordion defaultActiveKey={['0','1']} alwaysOpen>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Interest Rate Swap</Accordion.Header>
              <Accordion.Body>
                <RatesMarketDataPanel pricingData={pricingData}
                  businessDate={valuationDate}
                  pricingDisabledCallBack = { (message) => { setPricingDisabled() } }
                  setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) } }
                  curvePricingDataCallBack = { (curvePricingData) => { setCurvePricingData(curvePricingData) } }
                  ref={ratesMarketDataPanelRef}/>
            </Accordion.Body>
            </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Forward Rates</Accordion.Header>
              <Accordion.Body>
                <AnalyticsPanel
                  pricingDisabledCallBack = { (message) => { setPricingDisabled() } }
                  curvePricingData={curvePricingData}
                  setupTenor={setupTenor}/>
            </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </div>
      */}
    </div>
  );
});

export default InterestRateSwaps;
