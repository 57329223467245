import React from 'react'
import { useEffect, useState, useRef, useImperativeHandle } from 'react';
import Dropdown from "./Dropdown";
import BusinessDatePicker from "./BusinessDatePicker";
import { compareAsc, format, parseISO } from 'date-fns'
import LabeledNumericInput from "./LabeledNumericInput"
import TenorInput from "./TenorInput"

import ValueLabel from "./ValueLabel"

import { QuickSetupTenors, FixedFloat } from './SwapConstants'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import uuid from 'react-uuid'
import helpers from './helpers'
import PricerHelper from './PricerHelper'


//const url = "http://localhost:7922";


const QuickSetupPanel = React.forwardRef ((props, ref) => {

  const tenorRef = React.useRef();
  const swapSideRef = React.useRef();

  const min_value = 100000;

  const [swapSide, setSwapSide] = useState(FixedFloat[0]);
  const [notional, setNotional] = useState(min_value);
  const [start, setStart] = useState("0D");
  const [tenor, setTenor] = useState();
  const [index, setIndex] = useState();

  const [valueUpdated, setValueUpdated] = useState(false);

  const [fixedRate, setFixedRate] = useState(0.0);
  const [spread, setSpread] = useState(0.0);
  const [fairRate, setFairRate] = useState(0.0);
  const [fairSpread, setFairSpread] = useState(0.0);
  const [fixedLegBPS, setFixedLegBPS] = useState(0.0);
  const [floatingLegBPS, setFloatingLegBPS] = useState(0.0);

  const [quickSetupSetting, setQuickSetupSetting] = useState();
  const [pricingToken, setPricingToken] = useState();
  const [pricingResults, setPricingResults] = useState();

  const [fixedLegNPV, setFixedLegNPV] = useState(0.0);
  const [floatingLegNPV, setFloatingNPV] = useState(0.0);
  const [npv, setNPV] = useState(0.0);

  useEffect(() => {

    if ( props.curvePricingData !== undefined && props.setupTenor != undefined  )
    {
      handleChange("setup_tenor", props.setupTenor)
      setIndex(props.curvePricingData.index.Name)
    }

  },  [props.curvePricingData, props.setupTenor])


  const handleChange = (elementName, value) =>
  {
    if ( value === undefined || props.curvePricingData === undefined )
      return;

    var price_request_out = {};

    price_request_out["request_id"] = uuid();
    price_request_out["business_date"] = props.curvePricingData.business_date;
    price_request_out["fixing_type"] =  props.curvePricingData.index.FixingType;

    price_request_out["index"] = helpers.get_index_object(price_request_out, props.curvePricingData.index);
    helpers.populate_curve_object(price_request_out, props.curvePricingData.index, props.curvePricingData.forward_curve);

    switch (elementName) {

      case "setup_tenor":
          setTenor(value);
          price_request_out["operation"] = "PRICE_SWAP_AT_PAR";
          price_request_out["fwdstart"] = value['fwdstart']
          price_request_out["tenor"] = value['tenor']
          price_request_out["pay_or_recieve"] = swapSide
          price_request_out["notional"] = notional
        break;

      case "tenor":
          setTenor(value);
          price_request_out["operation"] = "PRICE_SWAP_AT_PAR";
          price_request_out["fwdstart"] = tenor.fwdstart;
          price_request_out["tenor"] = value;
          price_request_out["pay_or_recieve"] = swapSide;
          price_request_out["notional"] = notional;
        break;

      case "start":
            setStart(value);
            price_request_out["operation"] = "PRICE_SWAP_AT_PAR";
            price_request_out["fwdstart"] = value;
            price_request_out["tenor"] = tenor.tenor;
            price_request_out["pay_or_recieve"] = swapSide;
            price_request_out["notional"] = notional;
        break;

      case "notional":
          price_request_out["operation"] = "RE_PRICE_SWAP";
          price_request_out["swap"] = props.termStructure;
          price_request_out["swap"]["notional"] = value;
          setNotional(value);
        break;

      case "side":
          price_request_out["operation"] = "RE_PRICE_SWAP";
          price_request_out["swap"] = props.termStructure;
          price_request_out["swap"]["pay_or_recieve"] = value;
          setSwapSide(value);
        break;
        case "spread":
          price_request_out["operation"] = "RE_PRICE_SWAP";
          price_request_out["swap"] = props.termStructure;
          price_request_out["swap"][elementName] = value/10000.0;
          setSpread(value);
        break;
        case "fixed_rate":
          price_request_out["operation"] = "RE_PRICE_SWAP";
          price_request_out["swap"] = props.termStructure;
          price_request_out["swap"][elementName] = value/100.0;
          setFixedRate(value);
        break;
      default:
        return;

    }

    props.pricing_start();

    var results = PricerHelper.submit_request(price_request_out, (pricingResults) =>
    {
      if ( pricingResults["state"] == 2 )
      {
        props.Pricing_callback(pricingResults);
        setValueUpdated(false)
        //setTenor("5Y");
      }
    });
  }

  useEffect(() => {

    if ( props.termStructure !== undefined )
    {
      setFixedLegBPS( props.termStructure.fixed_leg_bps );
      setFloatingLegBPS( props.termStructure.floating_leg_bps );
      setFairRate( props.termStructure.par_rate * 100.0 );
      setFixedRate( (props.termStructure.fixed_rate * 100.0).toFixed(3) );
      setFairSpread( (props.termStructure.par_spread * 10000.0).toFixed(0));
      setSpread( (props.termStructure.spread * 10000.0).toFixed(0));
      setFixedLegNPV( props.termStructure.fixed_leg_npv );
      setFloatingNPV( props.termStructure.floating_leg_npv );
      setNPV( props.termStructure.npv );
    }

  },  [props.termStructure]);

  return (
      <Container>
       <Row>
          <Col>
          <Row>
          <Col>
          <Col style={{fontSize: '14px',fontWeight:600, color:'#65d2f3', textAlign:'right', marginBottom:'10px', marginRight:'20px'}}>
            {index}
          </Col>
          <Dropdown label='Fixed Leg' elementName="side"
                selected_value={swapSide}
                options={FixedFloat}
                onValueChange={handleChange}
                ref={swapSideRef}
                width_in={'100px'}
                />
            <LabeledNumericInput label="Notional" elementName="notional" type="notional"
              min_value={min_value}
              value={notional}
              value_updated = {(new_value)=>
                {
                  if ( new_value!==notional)
                    setValueUpdated(true)
                  else
                    setValueUpdated(false)
                }}
              onChange={handleChange}  step={1000000}/>
              <div style={{marginTop:'45px', opacity:'70%'}}>
                (Update the value and press Enter to re-price)
              </div>
          </Col>
          <Col>
            <TenorInput label="Tenor" elementName="tenor" value={tenor != undefined ? tenor.tenor : '0D' } onChange={handleChange}/>
            <TenorInput label="Fwd.Start" elementName="start" value={tenor != undefined ? tenor.fwdstart : '0D'} onChange={handleChange}/>
            <LabeledNumericInput label="Fixed Rate:" value={fixedRate} elementName="fixed_rate" onChange={handleChange}
            value_updated = {(new_value)=>
              {
                if ( new_value!==fixedRate)
                  setValueUpdated(true);
                else
                  setValueUpdated(false);
              }} step={0.01}/>
            <LabeledNumericInput label="Spread(BPS):" value={spread}
            elementName="spread" onChange={handleChange}
            value_updated = {(new_value)=>
              {
                if ( new_value!==spread)
                  setValueUpdated(true);
                else
                  setValueUpdated(false);
              }}
            step={1.0}/>
          </Col>
          </Row>
          </Col>
          <Col xs={4}>
            {
            <div style={ ( valueUpdated == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
            <ValueLabel label="Fixed Leg DV01:" value={fixedLegBPS}/>
            <ValueLabel label="Floating Leg DV01:" value={floatingLegBPS}/>
            <ValueLabel label="Fair Rate:" value={fairRate}  type='rate'/>
            <ValueLabel label="Fair Spread(BPS):" value={fairSpread} type='rate'/>
            <ValueLabel label="Fixed Leg NPV:" value={fixedLegNPV}/>
            <ValueLabel label="Float Leg NPV:" value={floatingLegNPV}/>
            <ValueLabel label="NPV:" value={npv}/>
            </div>
            }
          </Col>
      </Row>
      <Row>
      <Col style={{color:'yellow'}} id='swap_calc'>
        {props.error}
      </Col>
      </Row>
      </Container>
    )
 });


export default QuickSetupPanel;
