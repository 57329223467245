import { useEffect, useState } from 'react';
import React from 'react';
import { SwapIndexes } from './SwapIndexes'


import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const IndexPanel = React.forwardRef ((props, ref) => {

  const indexGridRef = React.useRef();

  //const [index, setIndex] = useState(['']);

  const indexGridColumnDefs = [
    { field: 'Currency',  sortable: true, width:80, cellStyle: {}, hide: true},
    { field: 'Index',   sortable: true, width:70, cellStyle: { }, hide: true},
    { field: 'Name',headerName:'Index',  sortable: false, flex:1, cellStyle: { }, hide: false},
    { field: 'Tenor',  sortable: false, width:70 , cellStyle: { }, hide: false},
    { field: 'FixingType',  sortable: false, flex:1 , cellStyle: {  }, hide: true},
    { field: 'Series',  sortable: false, flex:1 , cellStyle: {  }, hide: true},
    { field: 'rate', header:'Rate',  sortable: false, width:'60px',
      cellStyle: { }, hide: false,  cellStyle: {'textAlign': 'right' },  sortable: false, // editable: true,
      valueFormatter: (params) =>
      {
        if ( typeof params['value'] === 'number' )
        {
          return params['value'].toFixed(3);
        }
      }
    },

  ];

  const indexGridOptions = {

    rowSelection: 'single',

    getRowHeight: (params) => 25,

    onRowSelected(event) {

      if ( !event.node.selected )
        return;

      props.indexChangeCallback(event.data);
    },


    onGridReady: event => {

      indexGridRef.current.api.setRowData(SwapIndexes);
    },
  }

  useEffect(() => {

        // props.indexChangeCallback(index);
        console.log("SWAP INDEX : " + props.swapIndex );

        if ( props.swapIndex !== undefined && props.pricingData !== undefined )
        {
          var index_rates = props.pricingData['market_data']['INDEX_RATES'];

          indexGridRef.current.api.forEachNode( node=> {

            //console.log("NODE :: " + node.data.Name);

            var index_row = node.data;
            var index_series = index_row['Series']
            index_row['rate'] = index_rates[index_series]*100.0
            node.setData(index_row);

            if ( node.data.Name === props.swapIndex.Name ) {
              node.setSelected(true);
            }
          } )
        }

    }, [props.swapIndex, props.pricingData]);

   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"27.5vh", width: "100%", display: "inline-block", margin: "auto", padding:"10px"}}>
            <AgGridReact
                      //rowData={Indexes}
                      columnDefs={indexGridColumnDefs}
                      gridOptions={indexGridOptions}
                      ref={indexGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default IndexPanel;
