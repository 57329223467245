import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

//import Helpers from './Helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const CurveChart = React.forwardRef ((swapCurve, ref) => {

  const chartRef = useRef();

  const [displayDiscountCurve, setDisplayDiscountCurve] = useState(false);

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
  labels: [],
  datasets: [],
  });


  useEffect(() => {

          var labels = [];
          var rates = [];
          var discount_rates = [];
          var name = "N/A";

          var swap_curve = {}

          if ( swapCurve != undefined && swapCurve.swapCurve !== undefined) {

            //var curve_date = 0;
            name = swapCurve.swapIndex.Name;

            for (const [key, value] of Object.entries(swapCurve.swapCurve.curve_points))
            {
              rates.push((value * 100.0).toFixed(2));
              discount_rates.push((swapCurve.swapCurve.discount_points[key]*100.0).toFixed(2))
              labels.push(key);
            }

          }

          //var name = swapCurve.swapCurve.swapIndex.Name;


          const chart_data = {
            labels: labels,
            datasets: [ { data : rates,fill: true,
            backgroundColor: "rgba(152,195,121,0.2)",
            borderColor: "rgba(152,195,121)",
            label : name,
            borderWidth: 1.25,
            lineTension: 0.4,
          }]
          };


            chart_data['datasets'].push( {
              data : discount_rates,
              borderColor: 'rgb(255, 99, 132)',
              label : 'Discount Curve',
              borderWidth: 1.25,
              lineTension: 0.4,
              hidden: true
            } );


          const options = {
            responsive: true,
                maintainAspectRatio: false,
                elements: {
                       point:{
                           radius: 1.5,
                       }
                   },

  plugins: {
    title: {
      display: true,
      align: 'center',
      text: swapCurve.curveDataISO,
      position: 'right',
      color:"rgba(75,192,192,1)"
    },
  }
};

          setOptions(options);
          setData(chart_data);

  }, [swapCurve, displayDiscountCurve]);

  const handleClick = (event, chart) => {
  const elements = chartRef.current.getElementsAtEventForMode(
    event,
    "nearest", // Interaction mode
    { intersect: true },
    false
  );

  if (elements.length > 0) {
    const { index } = elements[0];
    const label = data.labels[index];
    const value = data.datasets[0].data[index];
    swapCurve.setup.tenorReset(label);
  }
};


  return (
        <div style={{height:'30vh', padding:"10px"}}>
          <Line options={options} data={data} ref={chartRef} style={{position: 'relative',
          height:'30vh', width:'80vw', border:'1px solid #424242', backgroundColor:'#192231'}}
          onClick={(event) => handleClick(event, event.chart)}/>
        </div>
    )
});

export default CurveChart;
