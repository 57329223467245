import { useEffect, useState } from 'react';
import React from 'react';


import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const EconomicDataPanel = React.forwardRef ((props, ref) => {

  const headlineGridRef = React.useRef();
  const headlineTrailGridRef = React.useRef();

  const [rowData, setRowData] = useState([]);
  const [rowTitleData, setRowTitleData] = useState([]);
  const [businessDate, setBusinessDate] = useState();
  const [newTitle, setNewTitle] = useState();
  const [summary, setSummary] = useState();
  const [link, setLink] = useState();

  /*
  const [industryName, setIndustryName] = useState([""]);


  useImperativeHandle(ref, () => ({
    populate_top_stock_data(rowData, business_date, period, industry_id ) {
      Top_stock_data_change(rowData, business_date,  period, industry_id );
  }
}));*/

  useEffect(() => {

    if ( headlineGridRef.current.api == undefined )
      return;

    if ( headlineGridRef !== undefined && rowData.length > 0  )
    {
      headlineGridRef.current.api.setRowData(rowData);
    }

    headlineGridRef.current.api.sizeColumnsToFit();
    headlineGridRef.current.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true))

  }, [rowData]);

  useEffect(() => {

    if ( headlineGridRef.current.api == undefined )
      return;

    if ( headlineTrailGridRef !== undefined && rowTitleData.length > 0 )
    {
      headlineTrailGridRef.current.api.setRowData(rowTitleData);
    }

    headlineTrailGridRef.current.api.sizeColumnsToFit();
    headlineTrailGridRef.current.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true));

  }, [rowTitleData]);

  const headlineGridColumnDefs = [
    { field: 'news_id', hide: true },
    { field: 'business_date', headerName: 'Date', flex: 0, width: 120,  sortable: true},
    { field: 'title',   cellStyle: {'text-align': 'left'}, flex: 1, sortable: true },
  ];

  const headlineTrailGridColumnDefs = [
    { field: 'news_id', hide: true },
    { field: 'timestamp',flex: 0, width: 150,  sortable: true   },
    { field: 'summary',  width: 120, cellStyle: {'text-align': 'left'}, flex: 1 },
    { field: 'link',   cellStyle: {'text-align': 'left'}, flex: 1, hide: true },
  ];

  const requestOptionsData = {
  };

  const headlineGridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        // console.log('row', event);

        // props.stockPickChangeCallback( event.data.id_key );
      },

      onRowSelected(event) {
        //alert("row " + event.node.data + " selected = " + event.node.selected);

        if ( event.node.selected === true )
        {
          //alert(event.node.data.title);

          //setBusinessDate(event.node.data.timestamp);
          //setNewTitle(event.node.data.title);

          var news_request_out = {};
          news_request_out['title'] = event.node.data.title;

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(news_request_out)
          };

          fetch(props.url + '/title_news_list/',requestOptions)
          .then(res => res.json())
          .then((result) => setRowTitleData(result.data));
        }
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25,

      onGridReady: event =>
      {
        fetch(props.url + '/news_list/',requestOptionsData)
        .then(res => res.json())
        .then((result) => setRowData(result.data));
      },
  }

  const headlineTrailGridOptions = {

      rowSelection: 'single',


      onRowSelected(event) {
        //alert("row " + event.node.data + " selected = " + event.node.selected);

        if ( event.node.selected === true )
        {
          setBusinessDate(event.node.data.timestamp);
          setNewTitle(event.node.data.title);
          setSummary(event.node.data.summary);
          setLink(event.node.data.link);
        }
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }


   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "25%", display: "inline-block", margin: "auto", paddingTop:"5px"}}>
            <AgGridReact columnDefs={headlineGridColumnDefs} gridOptions={headlineGridOptions} ref={headlineGridRef}>
            </AgGridReact>
            </div>
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "40%", display: "inline-block", margin: "auto", padding:"5px"}}>
            <AgGridReact columnDefs={headlineTrailGridColumnDefs} gridOptions={headlineTrailGridOptions} ref={headlineTrailGridRef}>
            </AgGridReact>
            </div>
            <div style={{width: "30%", display: "inline-block", margin: "auto", padding:"5px"}}>
              <div style={{textAlign: "left", padding:"10px", fontWeight:800}}>
                {businessDate} - {newTitle}
              </div>
              <div style={{textAlign: "left", padding:"10px", fontWeight:400}}>
                {summary}
              </div>
              <div>
                <a href={link} target="_blank">Link</a>
              </div>
            </div>
          </div>
      </div>
     )
  });

export default EconomicDataPanel;
