import { useEffect, useState } from 'react';

import uuid from 'react-uuid'
import React, { createContext, useCallback } from 'react';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import helpers from './helpers'
import PricerHelper from './PricerHelper'
import IndexPanel from './IndexPanel'
import MarketDataPanel from './MarketDataPanel'
import CurveChart from './CurveChart';
import ForwardRateMatrix from './ForwardRateMatrix'

import { SwapIndexes, SwapIndexTenors } from './SwapIndexes'

import { compareAsc, format, parseISO } from 'date-fns'

import BusinessDatePicker from "./BusinessDatePicker"
import { Container, Row, Col } from 'react-bootstrap/'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const { forwardRef, useRef, useImperativeHandle } = React;

const RatesMarketDataPanel = React.forwardRef ((props, ref) => {

  const marketDataRef = useRef()

  const [swapIndex, setSwapIndex] = useState()
  const [curveDate, setCurveDate] = useState()
  const [curveDateISO, setCurveDateISO] = useState()
  const [curvePricingData, setCurvePricingData] = useState()
  const [tenor, setTenor] = useState()
  const [swapCurve, setSwapCurve] = useState()
  const [error, setError] = useState()
  const [pricingDisabled, setPricingDisabled] = useState()
  const [panel, setPanel] = useState()
  const [forwardCurvePricingData, setForwardCurvePricingData] = useState()
  const [forwardRateMatix, setForwardRateMatrix] = useState()


  const marketDataPanelSetup = {

    pricingData:{curvePricingData},
    selected_tenor:{tenor},

    curve_price_callback : ( priced_curve ) => {

        if ( priced_curve.error_code == 0 )
        {
          setSwapCurve(priced_curve);
          setError("");
          setPricingDisabled(false)
        } else {
          console.log( "Error : " +  priced_curve.error );
          setError(priced_curve.error);
          setPricingDisabled(true)
        }

        setPanel('curve');
      },

    pricing_start : () =>
    {
      setPricingDisabled(true)
    },

    tenorReset : ( tenor ) => {
        setTenor(tenor);
        setPanel('curve');
      },
  };


/*
useEffect( () => {

  if ( props.curvePricingDataCallBack !== undefined && tenor !== undefined )
  {
    console.log(tenor)
    console.log(curvePricingData)

    const updateCurvePriceObject = {...curvePricingData}

    updateCurvePriceObject['tenor'] = tenor
    props.curvePricingDataCallBack(updateCurvePriceObject)
  }

},[tenor, curvePricingData])
*/


useEffect( () => {

  if ( props.curvePricingDataCallBack !== undefined && swapCurve !== undefined )
  {
    //if ( swapCurve !== undefined )
    //{
      const updateCurvePriceObject = {...curvePricingData}

      var forward_curve = []
      curvePricingData.forward_curve.forEach((curve_point, i) => {

        console.log(curve_point)

        curve_point.FixedRate = (parseFloat(swapCurve['curve_points'][curve_point.Tenor])*100.0).toFixed(3)
        curve_point.rate = curve_point.FixedRate

        forward_curve.push(curve_point)

        console.log(curve_point)

      });

      updateCurvePriceObject['forward_curve'] = forward_curve
      props.curvePricingDataCallBack(updateCurvePriceObject)
      console.log(swapCurve)
    //} else {
    //  props.curvePricingDataCallBack(curvePricingData)
    //}
  }

},[curvePricingData, swapCurve])


useEffect( () => {

  if ( tenor !== undefined && props.setupTenorCallBack !== undefined )
  {
    var setup_tenor = {}
    setup_tenor['fwdstart'] = '0D'
    setup_tenor['tenor'] = tenor

    props.setupTenorCallBack(setup_tenor)
  }

},[tenor])



  useEffect( () => {

    //setSwapPricingResults(swap_defaults);
    setSwapCurve(undefined);

    if ( swapIndex !== undefined && props.pricingData !== undefined  )
    {

      var market_data = props.pricingData['market_data']

      var curve_pricing_data = {};
      curve_pricing_data['index'] = swapIndex;
      curve_pricing_data['business_date'] = market_data['VALUATION_DATE'];

      /*if ( curveDateISO == undefined )
      {
        var curve_date = 0;
        var curve_dates = Object.keys(props.marketData[swapIndex.Name]);

        curve_dates = curve_dates.map(Number)
        curve_date = Math.max(...curve_dates);

        var curve_date_js = helpers.int_to_iso_date(curve_date);
        var curve_date_str = parseISO(curve_date_js);

        setCurveDate(curve_date_str)
        setCurveDateISO(curve_date_js);
      }*/

      curve_pricing_data['forward_curve'] = helpers.get_market_data(swapIndex, props.pricingData);

      if ( curve_pricing_data['forward_curve'] != undefined )
      {
        setCurvePricingData(curve_pricing_data);
      } else if (curveDateISO != undefined) {
        setError("Forward curve not available for " + curveDateISO );
        setPricingDisabled(false)
      }
  }
},[swapIndex, props.pricingData])

  useEffect( () => {

      if (props.pricingData !== undefined )
      {
        setSwapIndex(SwapIndexes[1])
      }

  },[props.pricingData])

/*
useEffect( () => {

    if (props.businesDate !== undefined && props.marketData !== undefined )
    {
      //setSwapPricingResults(swap_defaults)
      //setValuationDate(parseISO(props.businesDate))
      var curve_pricing_data = {}
      curve_pricing_data['index'] = swapIndex
      curve_pricing_data['business_date'] = props.businessDate
      curve_pricing_data['forward_curve'] = helpers.get_market_data(swapIndex, SwapIndexTenors, curveDateISO, props.marketData)
      setCurvePricingData(curve_pricing_data)
    }
},[props.businessDate, props.marketData, swapIndex, curveDateISO])


  useEffect( () => {

          if (panel !== undefined  && curvePricingData )
          {
            setForwardCurvePricingData(curvePricingData)
          }
},[panel, curvePricingData])

useEffect( () => {

        if (props.pricingDisabledCallBack !== undefined  && pricingDisabled !== undefined )
        {
          props.pricingDisabledCallBack('re-pricing curve')
        }
},[props.pricingDisabledCallBack, pricingDisabled])
*/

   return (
     <div className="ag-theme-balham-dark">
       <Row>
        <Col>
          <Row>
            <Col>
              <IndexPanel  swapIndex={swapIndex} pricingData={props.pricingData}
                indexChangeCallback={ (swap_index) => { setSwapIndex(swap_index); }}/>
            </Col>
            <Col>
              <Row>
                <MarketDataPanel setup={marketDataPanelSetup} swapCurve={swapCurve} ref={marketDataRef}/>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <div style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4",  margin: "auto"} : {}}>
                <CurveChart  setup={marketDataPanelSetup} swapIndex={swapIndex} swapCurve={swapCurve} curveDataISO={curveDateISO}/>
          </div>
        </Col>
        </Row>
        <Row>
          <Col style={{fontWeight:500, color:'rgb(255, 99, 132)', paddingLeft:'50px',fontSize:15}}>{error}</Col>
        </Row>
     </div>
    )
  });

export default RatesMarketDataPanel;
