import React from "react";

//export const PRICER_URL = "http://localhost:8000";
//export const PRICER_URL = "https://ustreasuries.online";
//export const SESSION_URL = "https://ustreasuries.online";
//export const SESSION_URL = "https://vps-48149b0f.vps.ovh.us/"
//export const PRICER_URL = "https://vps-48149b0f.vps.ovh.us:2443/";
//export const SESSION_URL = "https://vps-48149b0f.vps.ovh.us:2443/";
//export const CALCULATOR_COUNT = 8;
//export const PRICER_URL = "https://vps-48149b0f.vps.ovh.us:2443/";
//export const SESSION_URL = "http://localhost:8000";

//export const URL = "https://options.ustreasuries.online";
//export const PRICER_URL = "https://options.ustreasuries.online";
//export const SESSION_URL = "https://ustreasuries.online";

export const SESSION_URL = "https://45-33-8-23.ip.linodeusercontent.com:2443"
export const CALCULATOR_COUNT = 2;
export const PRICER_URL = "https://45-33-8-23.ip.linodeusercontent.com:2443";
