import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import helpers from './helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const LineChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();


  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false
  });

  const [yieldCurve, setYieldCurve] = useState();
  const [displayZeroCurve, setDisplayZeroCurve] = useState(false);


  useEffect(() => {

          var labels = [];
          var yields = [];

          var yield_curve = {}

          if ( yieldCurve === undefined)
            return;

          yield_curve = yieldCurve.yield_curve;

          var curve_date =  helpers.int_to_date(yieldCurve.curve_date);

          for (const [key, value] of Object.entries(yield_curve))
          {
            yields.push(value * 100.0);
            labels.push(key);
          }

          var zero_curve = {}
          var zero_rates = [];

          zero_curve = yieldCurve.zero_curve;

          for (const [key, value] of Object.entries(zero_curve))
          {
            zero_rates.push(value * 100.0);
          }

          const chart_data = {
            labels: labels,
            datasets: [
              {
                data : yields,fill: true,
                backgroundColor: "rgb(255, 165, 0, 0.1)",
                borderColor: '#FFA500',
                label : 'Yield Curve',
                borderWidth: 1.25,
                lineTension: 0.4,
              }, {
                data: zero_rates,
                borderColor: '#3d6a7b',
                backgroundColor: '#3d6a7b',
                label : 'Zero Curve',
                hidden: true
              }
              ]
          };

          /*if (displayZeroCurve == true )
          {
            chart_data['datasets'].push( {
              'data' : zero_rates,
              'borderColor': '#3d6a7b',
              'label' : 'Zero Curve'
            }
          );
        }*/

          const options = {
            plugins: {
              tooltip: {
                callbacks: {
                  afterFooter: (legendItem) => {
                      console.log('Legend item hovered:', legendItem[0].label);
                      props.tenorScrollCallback(legendItem[0].label);
                    },
                }
              },
              legend: {
                        //display: false,
                        /*onHover: (event, legendItem, legend) => {
                            // Handle the hover event here
                            console.log('Legend item hovered:', legendItem);
                          },
                          labels: {
                            display: false,
                            usePointStyle: true
                          },*/

              },
              title: {
                display: true,
                align: 'center',
                text: curve_date,
                position: 'right',
                color:"rgba(75,192,192,1)",
              },
            },
            elements: {
                   point:{
                       radius: 2.5,
                   }
               },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                //beginAtZero: true,
                //max: 5
              },
            },
          };

          setOptions(options);
          setData(chart_data);

  }, [yieldCurve, displayZeroCurve]);

  useEffect(() => {

    if (props.pricingResults.yield_curve !== undefined )
    {
      const yield_curve = props.pricingResults.yield_curve;
      const curve_date = props.pricingResults.curve_date;
      const zero_curve = props.pricingResults.zero_curve;

      var curve_results = {};
      curve_results["yield_curve"] = yield_curve;
      curve_results["curve_date"] = curve_date;
      curve_results["zero_curve"] = zero_curve;

      setYieldCurve(curve_results);
    }

  }, [props.pricingResults]);


  return (
      <div className="ag-theme-balham-dark">
        <Line options={options} data={data} ref={chartRef} style={{position: 'relative',
        height:'30vh', width:'80vw', border:'1px solid #424242', backgroundColor:'#192231'}}/>
      </div>
  );
});

export default LineChart;
