import React from "react";


export const SwapIndexes = [

  { Name: 'USD/Ameribor/OIS-1Y',Series:'AMERIBOR', Currency: 'USD', Index:'AMERIBOR', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS', FamilyName:'SOFR', FixingDays:0,Calendar:'US government bond market', DayCounter:'Act/360' },
  { Name: 'USD/Ameribor/IRS-3M',Series:'AMBOR90T', Currency: 'USD', Index:'Libor', Tenor:'3M', FixingType:'IsdaFixPm', Pricing:'IRS' },
  { Name: 'USD/SOFR/1Y',Series:'SOFR', Currency: 'USD', Index:'SOFR', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS', FamilyName:'SOFR', FixingDays:0,Calendar:'US government bond market', DayCounter:'Act/360' },
  { Name: 'USD/Libor/IsdaFixAm-3M',Series:'SOFR90DAYAVG', Currency: 'USD', Index:'Libor', Tenor:'3M', FixingType:'IsdaFixAm', Pricing:'IRS' },
  { Name: 'USD/Libor/IsdaFixPm-3M',Series:'SOFR180DAYAVG', Currency: 'USD', Index:'Libor', Tenor:'3M', FixingType:'IsdaFixPm', Pricing:'IRS' },

]


export const SwapIndexTenors = [

    /*'1M','3M',*/'6M', /*'9M',*/ '1Y', '2Y', '3Y', '5Y', '7Y', '10Y', '15Y', '20Y', '30Y'

]
