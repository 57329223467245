

//const url = "https://ustreasuries.online";
//const url = "http://localhost:8000";

//const url = window.location.href;

//export const PRICER_URL = "http://localhost:8080";
//export const SESSION_URL = "http://localhost:8000";
//export const URL = "https://options.ustreasuries.online";
//export const PRICER_URL = "http://localhost:8000";
//export const PRICER_URL = "https://ustreasuries.online"
//export const SESSION_URL = "https://ustreasuries.online";
import {PRICER_URL} from "../helpers/Constants"


const PricerHelper = {


  submit_request: function(request, callback)
  {
      var result_out = {}

      request["portal"] = "SWAP_PORTAL";
      request["pricing"] = request.index.Pricing;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      };

      fetch(PRICER_URL + '/submit_request/', requestOptions)
        .then(res => res.json())
        .then(pricingToken => {

          PricerHelper.check_result(pricingToken, callback);

        } );

      return result_out;
  },

  check_result: function(pricingToken, callback )
  {
    setTimeout(() => {

      var check_results = {};
      check_results["request_id"] = pricingToken.request_id;
      check_results["url"] = pricingToken.url;
      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(check_results) };
      fetch(PRICER_URL + '/check_request/', requestOptionsResults) .then(res => res.json())
      .then(check_result =>
        {
          if ( check_result["state"] == 2  )
          {
            console.log(check_result["state"]);
            callback(check_result);
          } else
            PricerHelper.check_result(pricingToken, callback);
        }
      );

    }, 300);
  },

  get_market_data: function( callback )
  {
    const requestOptionsData = {
    };

      fetch(PRICER_URL + '/swap_data',requestOptionsData)
      .then(res => res.json())
      .then(result => callback(result))
  },

};

export default PricerHelper;
