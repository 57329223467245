import React from 'react'


import { useEffect, useState, useRef } from 'react';

import uuid from 'react-uuid'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import helpers from './helpers'
import PricerHelper from './PricerHelper'
import QuickSetupPanel from './QuickSetupPanel'
import SwapPanel from './SwapPanel'
import CashflowsPanel from './CashflowsPanel'
import ForwardRateSurface from './ForwardRateSurface'
import ForwardRateMatrix from './ForwardRateMatrix'
import RatesMarketDataPanel from './RatesMarketDataPanel'

import Accordion from 'react-bootstrap/Accordion';


const AnalyticsPanel = React.forwardRef ((props, ref) => {

  const quickSetupRef = useRef()
  const swapPanelRef = useRef()
  const ratesMarketDataPanelRef = useRef()

  const [pricingDisabled, setPricingDisabled] = useState()
  const [pricingData, setPricingData] = useState()
  const [valuationDate, setValuationDate] = useState(new Date())
  const [swapPricingResults, setSwapPricingResults] = useState()
  const [curvePricingData, setCurvePricingData] = useState()
  const [error, setError] = useState()
  const [index, setIndex] = useState()
  const [pricingLayout, setPricingLayout] = useState('IRS')

  const [forwardRateMatix, setForwardRateMatrix] = useState()

  const [setupTenor, setSetupTenor] = useState()


  const checkVisible = (elm) =>
  {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  useEffect( () => {

    if ( curvePricingData !== undefined )
    {
      setSwapPricingResults(undefined)
      setIndex(curvePricingData.index)
      setPricingLayout(curvePricingData.index.Pricing)
      setPricingDisabled(true)
    }

  },[curvePricingData])

  useEffect( () => {

    if ( setupTenor !== undefined )
    {
      document.getElementById("swap_calc").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

  },[setupTenor])


/*  useEffect(() => {

    if (swapPricingResults !== undefined && props.swapPricingResultsCallBack !== undefined )
    {
        props.swapPricingResultsCallBack(swapPricingResults)
    }

}, [swapPricingResults]);*/

  return (
          <div id="swap_calc"  style={ ( pricingDisabled == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
            <Accordion defaultActiveKey={['0','1','2']} alwaysOpen>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Interest Rate Swap</Accordion.Header>
                <Accordion.Body>
                  <RatesMarketDataPanel
                    pricingData={props.pricingData}
                    businessDate={props.valuationDate}
                    pricingDisabledCallBack = { (message) => { setPricingDisabled(true) } }
                    setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) } }
                    curvePricingDataCallBack = { (curvePricingData) => { setCurvePricingData(curvePricingData) } }
                    ref={ratesMarketDataPanelRef}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
            <Accordion.Header>Forward Rate Matrix</Accordion.Header>
              <Accordion.Body>
              <Row>
              <Col>
                <ForwardRateMatrix curvePricingData={curvePricingData}
                Pricing_callback={ (pricing_results) => {
                  if ( pricing_results.error_code == 0 )
                  {
                    setError("");
                    setPricingDisabled(false)
                  } else {
                    console.log( "Error : " +  pricing_results.error );
                    setError(pricing_results.error);
                    setPricingDisabled(true)
                  }
                  setForwardRateMatrix(pricing_results);
                  //setPricingDisabled(false);
                  //setForwardCurvePricingData(undefined)
                }} pricing_start = { () => { setPricingDisabled(true);}}
                setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) } }/>
              </Col>
              <Col>
                <ForwardRateSurface swapIndex={index} forward_rate_matrix={forwardRateMatix} setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) }}/>
              </Col>
              </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>Term structure and Cashflows</Accordion.Header>
                <Accordion.Body>
                <div className="ag-theme-balham-dark">
                  <Row style={{margin:'10px'}} >
                  <Col>
                    <Row style={{paddingBottom:'10px'}}>
                      <Col style={{paddingBottom:'10px', paddingTop:'10px', border:'1px solid #424242',backgroundColor:'#21222A'}}>
                      <QuickSetupPanel curvePricingData={curvePricingData} termStructure={swapPricingResults}  setupTenor={setupTenor}
          error={error}
          Pricing_callback={ (pricing_results) => {
              if ( pricing_results.error_code == 0 )
              {
                setSwapPricingResults(pricing_results.my_swap);
                setError("");
                setPricingDisabled(false)
              } else {
                console.log( "Error : " +  pricing_results.error );
                setError(pricing_results.error);
                setPricingDisabled(true)
              }

              //setPricingDisabled(false);
          }}

          pricing_start = { () => {
            setPricingDisabled(true);
          }}

          ref={quickSetupRef}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col id="swap_calc" style={{paddingBottom:'10px', border:'1px solid #424242', backgroundColor:'#21222A'}}>
                        <SwapPanel curvePricingData={props.curvePricingData}
                        style={{paddingTop:'10px', backgroundColor:'#21222A'}}
                  termStructure={swapPricingResults} pricingLayout={pricingLayout}
                  Pricing_callback={ (pricing_results) => {
                    if ( pricing_results.error_code == 0 )
                    {
                      setSwapPricingResults(pricing_results.my_swap);
                      setError("");
                      setPricingDisabled(false)
                    } else {
                      console.log( "Error : " +  pricing_results.error );
                      setError(pricing_results.error);
                      setPricingDisabled(true)
                    }
                    //setPricingDisabled(false);
                  } }
                  pricing_start = { () => { setPricingDisabled(true); }} ref={swapPanelRef}/>
                      </Col>
                    </Row>
                  </Col>
                  <Col id='swap_calc'>
                    <CashflowsPanel termStructure={swapPricingResults} results={swapPricingResults}/>
                  </Col>
                  </Row>
                </div>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <div id='swap_calc'>
            </div>
          </div>
    )
 });



export default AnalyticsPanel;
