import { useEffect, useState } from 'react';

import uuid from 'react-uuid'
import React, { createContext, useCallback } from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import helpers from './helpers'
import PricerHelper from './PricerHelper'
import { Container, Row, Col } from 'react-bootstrap/'


import BusinessDatePicker from "./BusinessDatePicker";

const { forwardRef, useRef, useImperativeHandle } = React;


const MarketDataPanel = React.forwardRef ((props, ref) => {

  const marketDataGridRef = React.useRef();
  const indexRef = React.useRef();
  const [tenor, setTenor] = useState('5Y');
  const [pricing, setPricing] = useState(undefined);
  const [curveDate, setCurveDate] = useState(undefined);

  const [forwardCurve, setForwardCurve] = useState();
  const [adjustedForwardCurve, setAdjustedForwardCurve] = useState();


  const marketDataGridColumnDefs = [
      { field: 'ticker', headerName: 'Swap', cellStyle: {'textAlign': 'left',  opacity: '0.5'}, width: 'auto',  sortable: true },
      { field: 'Tenor',   cellStyle: {'textAlign': 'right'  }, flex: 1,  sortable: false },
      { field: 'rate',   cellStyle: {'textAlign': 'right' },  flex: 1,  sortable: false,  editable: true,
      valueFormatter: (params) =>
        {
          if ( typeof params['value'] === 'number' )
          {
            return params['value'].toFixed(3);
          }
        }
      }
  ];


  useEffect(() => {

    if ( pricing === false && tenor !== undefined )
      props.setup.tenorReset(tenor);

  }, [ tenor, pricing ] );

  const marketDataGridOptions = {

    getRowHeight: (params) => 25,
    rowSelection: 'single',
    onCellClicked (e) {
      const field = e.colDef.field;
      const colIndex = e.columnApi.getAllColumns()
        ?.findIndex((col) => col.getColDef().field === field);

        if ( field === 'Tenor')
        {
            setTenor(e.value);
        }

        console.log(field, colIndex);
    }
    /* onRowSelected(event) {

      if ( !event.node.selected )
        return;

      setTenor(event.data.Tenor);
    },*/

  }

  const reprice_curve = ( business_date, swap_index, market_data ) =>
  {

    if ( swap_index ===undefined || market_data === undefined )
      return

    console.log(market_data)

    var price_request_out = {};

    price_request_out["request_id"] = uuid();
    price_request_out["operation"] = "PRICE_CURVE";
    price_request_out["business_date"] = business_date;
    price_request_out["fixing_type"] = swap_index.FixingType;

    price_request_out["index"] = helpers.get_index_object(price_request_out, swap_index );
    helpers.populate_curve_object(price_request_out, swap_index, market_data);


    var curve_tenor_array = [];
    /*curve_tenor_array.push("1M");
    curve_tenor_array.push("3M");*/
    curve_tenor_array.push("6M");
    /*curve_tenor_array.push("9M");*/
    for (var curve_tenor = 1; curve_tenor <= 30; curve_tenor++)
    {
      var swap_curve_venor = curve_tenor + "Y";
      curve_tenor_array.push(swap_curve_venor);
    }

    price_request_out["swap_curve_point_request"] = curve_tenor_array;

    props.setup.pricing_start();

    setPricing(true);

    var results = PricerHelper.submit_request(price_request_out, (pricingResults) =>
    {
      props.setup.curve_price_callback(pricingResults);

      marketDataGridRef.current.api.forEachNode( node=> {
              if ( node.data.Tenor === tenor ) {
                  node.setSelected(true);
                }
              });

      setPricing(false);
    });
  };

  useEffect(() => {

      if ( props.setup.pricingData.curvePricingData === undefined )
        return;

      indexRef.current = props.setup.pricingData.curvePricingData;

      //setForwardCurve(indexRef.current.forward_curve);

      reprice_curve( indexRef.current.business_date, indexRef.current.index, indexRef.current.forward_curve );

    }, [ props.setup.pricingData.curvePricingData ] );

    useEffect(() => {

        if ( props.swapCurve === undefined || props.setup.pricingData.curvePricingData === undefined )
          return;

          var forward_curve = []

          props.setup.pricingData.curvePricingData.forward_curve.forEach((item, i) => {

            var rate = props.swapCurve.curve_points[item['Tenor']]

            if ( rate !== undefined )
            {
              //var forward_rate = structuredClone(item)
              item['rate'] = rate;
              forward_curve.push(item)
            }
            console.log( item['Tenor'] + " - " + rate);
          });

          setForwardCurve(forward_curve);

          /*
        props.swapCurve.curve_points.forEach((item, i) => {
          console.log(item)
        });*/


        //indexRef.current = props.setup.pricingData.curvePricingData;

        //setForwardCurve(indexRef.current.forward_curve);

        //reprice_curve( indexRef.current.business_date, indexRef.current.index, indexRef.current.forward_curve );

      }, [ props.swapCurve, props.setup]);

      useEffect(() => {

          if ( forwardCurve === undefined )
            return;

          var adjustedForwardCurve = [];

          forwardCurve.forEach((item, i) => {
              var forward = {...item};
              forward['rate'] = (item['rate']*100.0).toFixed(3);
              adjustedForwardCurve.push(forward)
          });

          setAdjustedForwardCurve(adjustedForwardCurve)

          },
       [forwardCurve] );


    const onCellEditingStopped = useCallback((event) =>
    {

      if (event.oldValue !== event.newValue) {
         //params.data["comments"] = params.newValue;
         var adjustedForwardCurve = [];

         marketDataGridRef.current.props.rowData.forEach((item, i) => {
             var forward = {...item};
             forward['rate'] = item['rate']/100.0;
             adjustedForwardCurve.push(forward)
         });

           reprice_curve(indexRef.current.business_date, indexRef.current.index, adjustedForwardCurve);
         return true;
       }
       else {
         return false;
       }

    }, []);


   return (
      <Container>
        <div className="App-Row">
                  <div className="ag-theme-balham-dark" style={{verticalAlign:"top",
                  height:"26.5vh", width: "100%", display: "inline-block",
                    margin: "auto", paddingTop:"10px"}}>
            <AgGridReact
                        rowData={adjustedForwardCurve}
                        columnDefs={marketDataGridColumnDefs}
                        gridOptions={marketDataGridOptions}
                        onCellEditingStopped={onCellEditingStopped}
                        ref={marketDataGridRef}>
            </AgGridReact>
          </div>
          <div style={{paddingTop:'5px',color:'#ffeed9', justify:'center', align:'right', margin:'auto'}}>
            (Double-click or Press Enter in the rate field to update and re-price)
          </div>
          </div>
      </Container>
     )
  });

export default MarketDataPanel;
