import { useEffect, useState } from 'react';
import React from 'react';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const CashflowsPanel = React.forwardRef ((props, ref) => {

  const cashflowsGridRef = React.useRef();

  const [cashflows, setCashflows] = useState();

  const date_style =  {'textAlign': 'right', padding:'3px', opacity: '0.7'};

  const amountFormatter = (param) => {

    return parseInt(param)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

  const cashflowsGridColumnDefs = [
    { field: 'business_date', headerName: 'BusinessDate', sortable: true, width:'150px',cellStyle: {'textAlign': 'center', padding:'3px'}, },
    {
      headerName : 'FixedLeg',
      children: [
      { field: 'fixed_leg_coupon_start_date',  headerName: 'Start',   cellStyle: date_style, sortable: false, width:'100px' },
      { field: 'fixed_leg_coupon_end_date',  headerName: 'End', cellStyle: date_style,  sortable: false, width:'100px' },
      { field: 'fixed_leg_count_amount',   headerName: 'Amount', valueFormatter:function (params) {
        if (params.value !== undefined )
        {
          if ( (props.termStructure!= undefined && props.termStructure.pay_or_recieve==='Payer') )
            return '(' + amountFormatter(params.value) + ')';
          else
            return amountFormatter(params.value);
        }
      }, cellStyle: {'textAlign': 'right', padding:'3px',  color:(props.termStructure!= undefined && props.termStructure.pay_or_recieve==='Payer') ? 'rgb(255, 99, 132)':'rgba(75,192,192,1)'},  sortable: false, width:'100px', flex: 1},
      ]
    },
    {
      headerName : 'FloatingLeg',
      children: [
        { field: 'floating_leg_coupon_start_date', headerName: 'Start',  cellStyle: date_style,  sortable: false, width:'100px', flex: 1  },
        { field: 'floating_leg_coupon_end_date',  headerName: 'End',  cellStyle: date_style,  sortabledate_style: false, width:'100px', flex: 1  },
        { field: 'floating_leg_accrual_period',   cellStyle: date_style,  sortable: false, hide: true, flex: 1  },
        { field: 'floating_leg_fixing_date',   cellStyle: date_style,  sortable: false, hide: true, flex: 1  },
        { field: 'floating_leg_spread',   cellStyle: date_style,  sortable: false, hide: true,flex: 1  },
        { field: 'floating_leg_amount', headerName: 'Amount',  valueFormatter:function (params) {
          if (params.value !== undefined )
          {
            if ( (props.termStructure!= undefined && props.termStructure.pay_or_recieve==='Receiver') )
              return '(' + amountFormatter(params.value) + ')';
            else
              return amountFormatter(params.value);
          }
        }, cellStyle: {'textAlign': 'right', padding:'3px',  color:(props.termStructure!= undefined && props.termStructure.pay_or_recieve==='Payer') ? 'rgba(75,192,192,1)':'rgb(255, 99, 132)'},  sortable: false, width:'100px', flex: 1},
      ]
    }
  ];

  const cashflowsGridOptions = {

    getRowHeight: (params) => 25,
    onGridReady: event => {

      var defaultSortModel = [
        { colId: 'business_date', sort: 'asc', sortIndex: 0 }
      ];

      cashflowsGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },
    //getRowHeight: (params) => 25
}

useEffect(() => {

  if ( props.results === undefined || props.results.fixed_leg.cashflows === undefined || props.results.floating_leg.cashflows === undefined )
    return;

  var cashflows = {};

  for (const [date, cashflow] of Object.entries(props.results.fixed_leg.cashflows))
  {
    if ( cashflows[date] === undefined )
    {
      cashflows[date] = {};
      cashflows[date]['business_date'] = date;
      cashflows[date]['fixed_leg_coupon_start_date'] = cashflow.accrual_start_date;
      cashflows[date]['fixed_leg_coupon_end_date'] = cashflow.date;
      cashflows[date]['fixed_leg_count_amount'] = cashflow.amount;
    }
  }

  for (const [date, cashflow] of Object.entries(props.results.floating_leg.cashflows))
  {
    if ( cashflows[date] === undefined )
      cashflows[date] = {};

    cashflows[date]['business_date'] = date;
    cashflows[date]['floating_leg_coupon_start_date'] = cashflow.accrual_start_date;
    cashflows[date]['floating_leg_coupon_end_date'] = cashflow.date;
    cashflows[date]['floating_leg_accrual_period'] = cashflow.accrual_period;
    cashflows[date]['floating_leg_fixing_date'] = cashflow.fixing_date;
    cashflows[date]['floating_leg_spread'] = cashflow.spread;
    cashflows[date]['floating_leg_amount'] = cashflow.amount;

  }

  var cashflows_out = Object.values(cashflows)
  setCashflows(cashflows_out);

  /*
  var defaultSortModel = [
    { colId: 'business_date', sort: 'asc', sortIndex: 0 }
  ];

  cashflowsGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
  */
},  [props.results]);



   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "100%", display: "inline-block", margin: "auto"}}>
            <AgGridReact rowData={cashflows}
                        columnDefs={cashflowsGridColumnDefs}
                        gridOptions={cashflowsGridOptions}
                          ref={cashflowsGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default CashflowsPanel;
