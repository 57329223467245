export const QuickSetupTenors = [
  '1Y',
  '2Y',
  '3Y',
  '5Y',
  '7Y',
  '10Y',
  '15Y',
  '20Y',
  '25Y',
  '30Y'
];

export const FixedFloat = [
  'Payer',
  'Receiver'
];
