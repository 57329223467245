import React from 'react'
import Plot from 'react-plotly.js';

import { useEffect, useState } from 'react';

//import Helpers from './Helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const ForwardRateSurface = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState();
  const [layout, setLayout] = useState();

  const handleClick = (event) => {
    if (event.points) {
      event.points.forEach((point) => {
        console.log(`Clicked point:`, {
          x: point.x,
          y: point.y,
          data: point });

          var setup_tenor = {}
          setup_tenor['fwdstart'] = point.x;
          setup_tenor['tenor'] = point.y;

          props.setupTenorCallBack(setup_tenor);

      });
    }
  };

  useEffect(() => {

    if ( props.forward_rate_matrix !== undefined && props.forward_rate_matrix.forward_rates !== undefined )
    {

      var forward_rates = [];
      props.forward_rate_matrix.forward_rates.forEach(
          swap_tenor_forward_rates =>
            {
              var tenor_forward_rates = [];
              props.forward_rate_matrix.forward_tenors.forEach( forward_tenor =>
              {
                var forward_rate = swap_tenor_forward_rates[forward_tenor];
                tenor_forward_rates.push((parseFloat(forward_rate)*100.0).toFixed(3));
              });
              forward_rates.push(tenor_forward_rates);
            }
        );


      const surface_data = [


        {
          marker: { colors: ["#5eba7d", "#1565c0"] },

          color: "#fff",

          x: props.forward_rate_matrix.forward_tenors,
          y: props.forward_rate_matrix.swap_tenors,
          z: forward_rates,
          hovertemplate: 'Forward Start:%{x}<br>Tenor:%{y}<br>Fair Rate: %{z:.2f}<extra></extra>',
          type: 'surface',
          contours: {
   z: {
     show:true,
     label:'test',
     usecolormap: true,
     highlightcolor:"#42f462",
     project:{z: true}
   }
 },
        }
      ];

      setData(surface_data);

      var layout = {


        color: "#fff",
        font_color: "#fff",
        paper_bgcolor:"rgba(0,0,0,0)",
        autosize:true,
            autosize:true, margin:{ l:0,r:0,b:20,t:0 },
                                    title: props.swapIndex.Name,
                                    scene: {
                                      xaxis: {title:"Forward Start", color:'#505357',  gridcolor:'#8c8f90',   "gridwidth": 1 },
                                      yaxis: {title:"Swap Tenor", color:'#505357', gridcolor:'#8c8f90',   "gridwidth": 1},
                                      zaxis: {title:"Fair Rate", color:'#505357', gridcolor:'#8c8f90',   "gridwidth": 1 }
                                    },
};

  setLayout(layout);

};

  }, [props.forward_rate_matrix]);


  return(
      <div style={{/*display: "block", margin: "10px", borderRadius:'25px', width: "35vw", height: "35vh", border:'1px solid #424242'*/}}>
      <Plot layout={layout} data={data}  style={{ marginTop: "10px", width: "98%",
          height: "30vh", backgroundColor:'#192231',border:'1px solid #424242' }}
          onClick={handleClick} />
      </div>
    );
});

export default ForwardRateSurface;
